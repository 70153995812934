import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Video from "../components/video";

import "../style/normalize.css";
import "../style/all.scss";

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>
            <em>
              <em>
                Why do we teach music? Not because we expect you to major in
                music. Not because we expect you to play and sing all your life.
                Not so you can relax. But so you will be human. So you will
                recognize beauty. So you will be sensitive. So you will have
                something to cling to. So you will have more love, compassion,
                more gentleness, more good, in short, more life.{" "}
              </em>
            </em>
          </p>
          <figure className="kg-card kg-image-card kg-width-card">
            <Img
              fluid={data.teaching.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <h4 id="StudentAchievements">Student Achievements</h4>
          <h5 id="AcceptanceToCollege">Acceptances to College</h5>
          <ul>
            <li>The Julliard School, NY, 2023</li>
            <li>New England Conservatory of Music, MA, 2022</li>
            <li>Juilliard Pre-College, NY, 2019 </li>
            <li>University of Pennsylvania, PA, 2019</li>
          </ul>

          <h5 id="StudentAwards">Student Awards</h5>
          <ul>
            <li>
              International Music Talent Competition, 2nd prize, NY, Spring 2023
            </li>
            <li>Classical Viennese Festival, Gold medal, WA, 2023</li>
            <li>Simon-Fiset Competition, Honorable mention, WA, 2023</li>
            <li>Virtuoso Artists Festival, Gold medal, WA, 2022</li>
            <li>
              Russian Chamber Music Competition, Gold medal, Silver medal,
              Bronze medal, WA, 2023, 2021, 2020, 2018, 2016
            </li>
            <li>
              PAFE competition, solo division, concerto division, 1st prize, 2nd
              prize, honorable mention, WA, 2021, 2017
            </li>
            <li>Bach Festival, Gold medal, WA, 2023 ,2022, 2020</li>
            <li>Won Frances Walton Young Artist, WA, 2017</li>
          </ul>
          <h5 id="LocalOrchestraPlacements"> Local Orchestra Placements </h5>
          <ul>
            <li> NAfME All Northwest Orchestra, WA, 2023 - present</li>
            <li> Seattle Youth Symphony, WA, 2015 – present </li>
            <li> Bellevue Youth Symphony, WA, 2015 – present </li>
          </ul>

          <figure className="kg-card kg-image-card kg-width-card">
            <Img
              fluid={data.achievements.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <hr />
          <h4>Student Performances</h4>
          <h5>
            Haydn Cello Concerto No. 2 in D Major, first movement Allegro
            moderato
          </h5>
          <Video
            videoSrcURL="https://www.youtube.com/embed/J0h5z6Be3Cs"
            videoTitle="Sally Deng - Haydn Cello Concerto No. 2 in D Major, first movement Allegro moderato"
          />

          <h5>Bach Cello Suite No. 1, Prelude</h5>
          <Video
            videoSrcURL="https://www.youtube.com/embed/NfwCRMAxlv0"
            videoTitle="Owen Wu - Bach Cello Suite No. 1, Prelude"
          />

          <h5>Bach Cello Suite No. 2, Prelude</h5>
          <Video
            videoSrcURL="https://www.youtube.com/embed/pGfvwvHihXw"
            videoTitle="Alice Leng - Bach Cello Suite No. 2, Prelude"
          />
          <hr />
          <h4 id="Testimonies">Testimonies</h4>
          <blockquote>
            <em>
              Dr. Yang Lu is a very supportive, kind, and understanding teacher.
              She teaches me how to embrace the beauty of music and to find my
              passion and love for cello study. She is always encouraging me to
              challenge myself, and whenever I feel not confident, she would be
              there to assure me. Dr. Yang Lu also has given me so many
              opportunities to build up my confidence in playing the cello, that
              I will always cherish those moments in my cello journey. I look
              forward to her cello class every week because it’s so engaging and
              fun! She would not only teach me about technical parts of the
              music but also discuss the background and stories of the music. By
              helping me learn every piece on a deeper level — not just notes
              and rhythms — and experience the feel of the music myself, Dr.
              Yang Lu has truly opened a new door in my music learning. I
              learned how to put my emotion into playing music, which made
              everyday practice an enjoyable time and developed my skills in
              expressing musicality.
            </em>
          </blockquote>
          <em> - Katie Y. (Student) </em>
          <hr />
          <blockquote>
            <em>
              Ms. Yang Lu has helped me so much during the[se] months. I might
              not be able to play my best in front of her every lesson, but she
              can see my potential. Not only does she teach me what’s wrong, and
              right, but she also helps me learn from her teaching methods,
              encouraging me to think about why I made a mistake, and giving me
              more opportunities to realize my own abilities. She’s helped me
              become more independent, and know-how to practice by myself,
              repeating hard phrases during my practicing, and scheduling what I
              would like to accomplish. She focuses on what I need to work on
              and helps me build my phrases together by including detail to make
              sure I understand. I cannot believe how much has changed in my
              playing since the first day I had a lesson with her. I am eager to
              see what else I can improve on in future lessons.
            </em>
          </blockquote>
          <em> - Alice L. (Student) </em>

          <hr />
          <blockquote>
            <em>
              I am so happy to have Dr. Lu as my cello teacher. As a musician,
              she is professional, inspiring, and attentive to details. Outside
              of classes, she is patient, caring, and continually supports me.
              When I was auditioning for Juilliard, she flew all the way to New
              York with me! I really cherish and appreciate the guidance she
              gives me. Dr. Lu is an amazing teacher and friend to me, and
              anyone would be very lucky to have her as their teacher！
            </em>
          </blockquote>
          <em> - Sally D. (Student) </em>
          <figure className="kg-card kg-image-card kg-width-card">
            <Img
              fluid={data.speaking.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <hr />
          <blockquote>
            <em>
              My daughter started to play cello with Ms. Lu one and half year
              ago. Ms. Lu is easy to work with. She sets clear rules for both
              kids and parents to follow. And she is always dedicated to help
              for any questions, rehearsals and competitions outside of class
              time. She helped my daughter to build the solid basic skills
              including correct posture, bow holding, understanding the music
              definition and sight reading, etc. The way she teaches also helped
              my daughter to be responsible, be respective, and motivate her to
              be better. My daughter has improved so much in the past year in
              both playing cello and understanding music. I am very grateful for
              having Ms. Lu as my daughter’s cello teacher and would highly
              recommend her to any cello student and family who is seeking for a
              good cello teacher.
            </em>
          </blockquote>
          <em> - Cherry T. (Parent) </em>

          <hr />
          <blockquote>
            <em>
              Dr. Yang Lu is the cello teacher of my two daughters and has been
              mentoring them for nearly three years. She has extensive
              professional knowledge and teaching experience. My kids have made
              great progress under her guidance. For beginners, Dr. Yang Lu
              emphasizes mastering ba- sic skills. She often patiently corrects
              my kids' performance mistakes. In the practice, Dr. Yang Lu asks
              them to understand the pieces, add expressions and actions to
              enhance the music. She is so good at teaching students ac- cording
              to their talents. Dr.Yang Lu cares about her students very much,
              teaches and guides them with great patience and understanding. She
              has also held several recitals to provide opportunities for her
              students to com- municate learn from each other and accumulate
              playing experience at the stage. Under her guidance, learning the
              cello is not only to play the cello but also to experience and
              enjoy music with the cello.
            </em>
          </blockquote>
          <em> - Min S. (Parent) </em>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    teaching: file(relativePath: { eq: "teaching.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    achievements: file(relativePath: { eq: "achievements.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    speaking: file(relativePath: { eq: "speaking.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
);
